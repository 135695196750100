// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NpsEnvironmentConfig } from "./nps-environment-config";

export const npsEnvironment: NpsEnvironmentConfig = {
  production: false,
  api: {
    'CARIAD': {
      url: '',
      clientId: '',
      authority: '',
      scopes: ['']
    },
    'VWGPROD': {
      url: '',
      clientId: '',
      authority: '',
      scopes: ['']
    },
    'VWGST': {
      url: '',
      clientId: '',
      authority: '',
      scopes: ['']
    },
    'PowerCo': {
      url: '',
      clientId: '',
      authority: '',
      scopes: ['']
    },
    'VWGITC': {
      url: 'https://azfun-azuf-euw-s-nps-gitc.azurewebsites.net',
      clientId: '73593f74-5dd1-4458-b18b-0e5244646826',
      authority: 'https://login.microsoftonline.com/9f69edf3-ef71-467c-8885-5e380cdc20cd',
      scopes: ['api://73593f74-5dd1-4458-b18b-0e5244646826/API']
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
